#main-container {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
          "message"
          "viewer"
          "form"
  ;
  #main-message {
    grid-area: message;
    background: var(--cds-alias-object-container-background);
    border-radius: var(--cds-alias-object-border-radius-200);
  }
  #main-form {
    grid-area: form;
    padding: var(--cds-global-layout-space-xs);
    #main-form-select {
      margin-top: calc(-1 * var(--cds-global-layout-space-xs));
    }
    & > * {
      width: 100%;
      max-width: 450px;
    }
  }
  #main-viewer {
    grid-area: viewer;
    align-self: center;
    padding: var(--cds-global-layout-space-xs);
    display: flex;
    justify-content: center;
    align-items: center;
    #image-viewer {
      max-height: calc(max(200px, min(768px, 100vh - 430px)));
    }
  }
  @media (min-width: 768px) {
    align-self: center;
    grid-template-columns: minmax(400px, 1fr) minmax(auto, 1.168fr);
    grid-template-areas:
            "form viewer"
    ;
    column-gap: var(--cds-global-layout-space-sm);
    padding: var(--cds-global-layout-space-xs);
    #main-message {
      display: none;
    }
    #main-form {
      background: var(--cds-alias-object-container-background);
      border-radius: var(--cds-alias-object-border-radius-100);
      padding: var(--cds-global-layout-space-md);
      #image-input-label {
        background: #fff;
      }
    }
    #main-viewer {
      padding: 0;
      #image-viewer {
        max-height: calc(max(200px, min(768px, 100vh - 160px)));
        max-width: 1024px;
      }
    }
  }
}
#image-input-label {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--cds-alias-object-container-background);
  border: solid var(--cds-alias-object-border-width-100) var(--cds-alias-object-container-border-color);
  border-radius: var(--cds-alias-object-border-radius-100);
  cursor: pointer;
  gap: var(--cds-global-layout-space-xs);
  padding: var(--cds-global-layout-space-xs);
}
