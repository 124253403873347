#image-viewer {
  box-shadow: rgba(27, 43, 50, 0.5) 0 1px 3px 0;
  border-radius: 4px;
  &.image-compare-disabled {
    cursor: not-allowed;
    .icv__circle {
      border: none !important;
    }
    .icv__arrow-wrapper {
      display: none;
    }
  }
  .image-blured {
    filter: blur(4px);
  }
  .viewer-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: not-allowed;
  }
}